<template>
  <svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.32965 2.95012H5.20676C4.87546 2.95012 4.55774 3.08173 4.32348 3.31599C4.08921 3.55026 3.95761 3.86798 3.95761 4.19928V9.82048H2.08387C1.75258 9.82048 1.43485 9.68887 1.20059 9.45461C0.966324 9.22035 0.834717 8.90262 0.834717 8.57132V1.70097C0.834717 1.36967 0.966324 1.05194 1.20059 0.817682C1.43485 0.58342 1.75258 0.451813 2.08387 0.451813L7.08049 0.451813C7.41179 0.451813 7.72952 0.58342 7.96378 0.817682C8.19804 1.05194 8.32965 1.36967 8.32965 1.70097V2.95012ZM5.83134 3.5747H10.2034L12.0771 5.44844V11.6942C12.0771 12.0255 11.9455 12.3432 11.7112 12.5775C11.477 12.8118 11.1593 12.9434 10.828 12.9434H5.83134C5.50004 12.9434 5.18231 12.8118 4.94805 12.5775C4.71379 12.3432 4.58218 12.0255 4.58218 11.6942V4.82386C4.58218 4.49256 4.71379 4.17483 4.94805 3.94057C5.18231 3.70631 5.50004 3.5747 5.83134 3.5747Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCopy'
}
</script>
